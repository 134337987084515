import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { RoleEnum } from '../enums/role.enum';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as Sentry from '@sentry/browser';

@Injectable({
  providedIn: 'root',
})
export class AuthenticatedUserService {
  private user!: User;
  private userObservable = new BehaviorSubject<User | null>(null);

  constructor(private httpClient: HttpClient) {}

  setUser(user: User): void {
    Sentry.setUser({ email: user.email });
    this.user = user;
    this.userObservable.next(user);
  }

  getUser(): User {
    return this.user;
  }

  setHasAcceptedLatestTerms(value: boolean) {
    this.user.hasAcceptedLatestTerms = value;
  }

  updateUser(user: User): Observable<User> {
    return this.httpClient.put<User>(`${environment.serverUrl}/api/account/user`, user);
  }

  getUserSubject(): Observable<User | null> {
    return this.userObservable.asObservable();
  }

  getUserRole(): RoleEnum[] {
    return this.user.roleList;
  }

  addUserRole(newRole: RoleEnum) {
    this.user.roleList.push(newRole);
    this.userObservable.next(this.user);
  }

  getAuthenticatedUserApi(): Observable<User> {
    return this.httpClient.get<User>(`${environment.serverUrl}/api/account/user/authenticated`);
  }

  getUserInitials(firstName?: string, lastName?: string): string {
    let userInitials = '';
    if (firstName) {
      userInitials += firstName.charAt(0);
    }
    if (lastName) {
      userInitials += lastName.charAt(0);
    }
    return userInitials;
  }

  getUserFullName(user: User) {
    let userFullName = user.firstName || '';
    if (user.firstName && user.lastName) {
      userFullName += ' ';
    }
    userFullName += user.lastName || '';
    return userFullName;
  }

  isAdmin(roles = this.getUserRole()) {
    return roles.includes(RoleEnum.ADMINISTRATOR);
  }

  isResearcher(roles = this.getUserRole()) {
    return roles.includes(RoleEnum.RESEARCHER);
  }

  isCompanyUser(roles = this.getUserRole()) {
    return roles.includes(RoleEnum.COMPANY);
  }

  isAdminAndResearcher(roles = this.getUserRole()) {
    return roles.includes(RoleEnum.ADMINISTRATOR) && roles.includes(RoleEnum.RESEARCHER);
  }

  isPatient(roles = this.getUserRole()) {
    return roles.includes(RoleEnum.PATIENT);
  }

  isPatientOnly(roles = this.getUserRole()) {
    return roles.length === 1 && roles[0] === RoleEnum.PATIENT;
  }

  isCaregiver(roles = this.getUserRole()) {
    return roles.includes(RoleEnum.CAREGIVER);
  }

  isCaregiverOnly(roles = this.getUserRole()) {
    return roles.length === 1 && roles[0] === RoleEnum.CAREGIVER;
  }

  isPatientAndCaregiver(roles = this.getUserRole()) {
    return roles.includes(RoleEnum.PATIENT) && roles.includes(RoleEnum.CAREGIVER);
  }
}
